<template>
  <div class="m-new-address-modal" data-t="m-new-address-modal">
    <AddNewAddress platform="mobile" @handle-next-step="handleNextStep" />
  </div>
</template>

<script setup lang="ts">
import { useAddressesStore } from '../../stores/useAddressesStore'

const { refreshAddresses } = useAddressesStore()

const router = useRouter()

function handleNextStep() {
  refreshAddresses()
  router.replace({ query: {} })
}
</script>

<style scoped>
.m-new-address-modal {
  display: flex;
  flex-grow: 1;
}
</style>
